<template v-if="brand.den_id">
  <li v-if="pickBrands" class="disflex">
    <p v-if="!isNew" class="con">{{ idx + 1 }}</p>
    <p v-if="newValue && !isNew" class="con state">
      <span>
        <img alt="" src="../../../assets/images/design/NEW.svg" />
      </span>
    </p>
    <p v-else-if="!isNew" class="con state">
      <span v-if="rank_change > 0">
        <img alt="" src="../../../assets/images/design/ranking-up.svg" />
        {{ rank_change }}
      </span>
      <span v-else-if="rank_change < 0">
        <img alt="" src="../../../assets/images/design/ranking-down.svg" />
        {{ rank_change }}
      </span>
      <span v-else-if="rank_change === 0">-</span>
    </p>
    <div :class="`${!isNew ? '' : 'new'} con pro_desc_box`">
      <router-link :to="`brand/detail/${brand.den_id ? brand.den_id : ''}`">
        <image-bg-src
          :class="`${!isNew ? '' : 'new'} profile_img`"
          :src="brand.img_url"
          style="width: 30px; height: 30px"
        />
        <div class="profile_user">
          <h3 class="name">{{ brand.den_name }}</h3>
          <p class="desc">
            {{ brand.provider_concept1 == null ? '' : brand.provider_concept1 }}
            {{
              brand.provider_concept2 == null
                ? ''
                : `, ${brand.provider_concept2}`
            }}
          </p>
        </div>
      </router-link>
    </div>
    <p class="con">
      <book-mark-button
        :key="isPick"
        :pickItemId="den_id ? den_id : ''"
        :value="isPick"
        class="list"
        type="p"
        @update="handleClickBookMark"
      >
        {{ brand.COUNT }}
      </book-mark-button>
    </p>
  </li>
</template>

<script>
import { ref, watch } from 'vue-demi';
import BookMarkButton from '../Buttons/BookMarkButton.vue';
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
import usePick from '@/composables/user/usePick';

export default {
  props: [
    'item',
    'den_id',
    'idx',
    'newValue',
    'rank_change',
    'rising',
    'isNew',
  ],
  components: { ImageBgSrc, BookMarkButton },
  setup(props, context) {
    const { getUserPick, isPick: isPickBrands } = usePick();
    const isPick = ref(false);
    const pickBrands = ref(null);
    const brand = ref(props.item);
    let brandCount = ref(1);
    const loadPickBrands = async () => {
      const brands = await getUserPick('brands');

      pickBrands.value = brands;

      isPick.value = isPickBrands('brands', props.den_id);
    };

    loadPickBrands();
    watch(
      () => props.item,
      () => {
        brand.value = props.item;
      }
    );

    return {
      brand,
      isPick,
      props,
      brandCount,
      pickBrands,
      handleClickBookMark(checked) {
        const newItem = { ...brand.value };

        isPick.value = checked;

        newItem.count =
          Number(newItem.COUNT ? newItem.COUNT : '') + (checked ? 1 : -1);
        brand.value = newItem;
        context.emit('update:bookmark', brand.value);
      },
    };
  },
};
</script>

<style>
.profile_img.new img {
  left: 0;
}
.new.con.pro_desc_box {
  width: 100% !important;
}
.new.con.pro_desc_box .name {
  text-align: left;
}
.new.con.pro_desc_box .desc {
  text-align: left;
}
.new::after {
  display: none;
}
</style>
