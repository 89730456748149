<template>
  <filter-layout-popup
    :title="$t('Brand.NewStore')"
    :isShowButton="false"
    :isNewBrandPop="true"
    @close="$emit('close')"
  >
    <div id="new_brand" class="pop_body">
      <section class="ranking_list_wrap inner p04">
        <ul v-if="items">
          <brand-item
            v-for="(item, key, idx) in items"
            :key="item.den_id"
            :isNew="true"
            :item="item.data"
            :keyValue="item.key"
            :newValue="item.newValue"
            :rising="item.rising"
            :rank_change="item.rank_change"
            :idx="idx"
            @update:bookmark="handleUpdateItem"
          />
        </ul>
      </section>
    </div>
  </filter-layout-popup>
</template>

<script>
import FilterLayoutPopup from '../../../components/popups/FilterLayoutPopup.vue';
import BrandItem from '../../../components/ui/BrandItem/BrandItem.vue';

export default {
  components: { FilterLayoutPopup, BrandItem },
  props: ['items'],
  setup(props, context) {
    console.log(props, context);
    return {
      props,
      handleUpdateItem(item) {
        context.emit('update:bookmark', item);
      },
    };
  },
};
</script>

<style scoped>
.ranking_list_wrap {
  background: #fff;
}
#new_brand .layout_popup.filter_layout_pop {
  height: 100%;
}
#new_brand .layout_popup.filter_layout_pop,
#new_brand .layout_contain {
  height: 100%;
  overflow: auto;
}
</style>
