<template>
  <div class="wrap">
    <header class="layout_header">
      <div class="hinner">
        <nav class="gnb">
          <div class="left_header">
            <router-link to="/brand" class="menu fontEn on">
              {{ $t('Brand.RANKING') }}
            </router-link>
            <router-link to="/brand/look-book" class="menu fontEn">
              {{ $t('Brand.LOOKBOOK') }}
            </router-link>
          </div>
          <search-cart-icons />
        </nav>
      </div>
    </header>
    <div v-if="isLoading" class="layout_body">
      <!-- 필터 선택한 후 -->
      <filter-slider
        v-if="filterSlideItems.length > 0"
        :items="filterSlideItems"
        @click="handleClickTag"
      />
      <div class="inner">
        <article
          v-if="newBrands && newBrands.length > 0"
          class="new_store_wrap"
        >
          <span class="new_stcount"
            >{{ $t('Brand.NewStore') }} {{ newBrands.length }}</span
          >
          <ul class="new_stlist">
            <li class="disflex">
              <router-link :to="`brand/detail/${newBrands[0].data.den_id}`">
                <p class="con">
                  <image-bg-src
                    class="profile_img brand_only"
                    :src="newBrands[0].data.img_url"
                  />
                  <span class="profile_user">
                    {{ newBrands[0].data.den_name }}
                  </span>
                </p>
              </router-link>
              <button
                class="con r btn_more btn_pop_open flex_right"
                @click="isShowNewBrand = true"
              >
                {{ $t('Ect.More') }}
              </button>
            </li>
          </ul>
        </article>
        <section class="ranking_list_wrap">
          <ul v-if="brands">
            <brand-item
              v-for="(item, idx) in brands"
              :key="`${item.den_id}`"
              :idx="idx"
              :item="item.data"
              :den_id="item.den_id"
              :newValue="item.new"
              :rank_change="item.rank_change"
              :rising="item.rising"
              @update:bookmark="handleUpdateItem"
            />
          </ul>
        </section>
      </div>
    </div>
    <div v-else class="loading_wrap">
      <div class="loading_content">
        <img src="../../assets/images/design/loading.gif" alt="loading" />
      </div>
    </div>
    <button
      v-if="isLoading"
      type="button"
      class="band_filter btn_fix btn_lineblack btn_pop_open"
      @click="isShowFilter = true"
    >
      {{ $t('Brand.BrandFilter') }}
    </button>
    <BrandFilterPopup
      v-if="isShowFilter"
      v-model="filters"
      @close="isShowFilter = false"
      @input="handleInputFilter"
    />
    <sild-footer isToggleNav="true" />
    <new-brand
      v-if="isShowNewBrand"
      :items="newBrands"
      @close="handleCloseNewBrand"
      @update:bookmark="handleUpdateItem"
    />
  </div>
</template>
<script>
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import BrandAPI from '@/apis/BrandAPI';
import { reactive, toRefs } from 'vue-demi';
import BrandItem from '../../components/ui/BrandItem/BrandItem.vue';
import ImageBgSrc from '../../components/ui/ImageBlock/ImageBgSrc.vue';
import NewBrand from './popups/NewBrand.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import ArrayUtils from '../../utils/ArrayUtils';
import SearchCartIcons from '../../components/layouts/components/headers/components/RightHeader/SearchCartIcons.vue';
import BrandFilterPopup from './popups/BrandFilterPopup.vue';
import SearchAPI from '@/apis/SearchAPI';
import filterItems from '@/consts/FilterItems';
import FilterSlider from '../../components/ui/FilterSlider/FilterSlider.vue';

export default {
  components: {
    SildFooter,
    BrandItem,
    ImageBgSrc,
    NewBrand,
    SearchCartIcons,
    BrandFilterPopup,
    FilterSlider,
  },
  setup() {
    const { info } = useUserInfo();
    const params = {
      page: 0,
      limit: 200,
      mem_id: info?.mem_id ?? 0,
    };

    const state = reactive({
      filters: {},
      brands: null,
      newBrands: null,
      filterSlideItems: [],
      selectFilter: new Object(),
      isShowFilter: false,
      isShowNewBrand: false,
      isShowSort: false,
      filterSearchValue: null,
      isLoading: false,
    });

    const clearSearchParams = () => {
      params.page = 0;
      state.filters = {};
      state.hasMore = true;
      state.infiniteId = Date.now();
    };

    const createSearchParamValue = (key, value) => {
      switch (key) {
        case 'concept':
          return value.filter((concept) => concept);

        case 'style':
          return value.filter((style) => style);

        case 'price':
        case 'soldOut':
          return value;
        case 'rising':
          return value;
        default:
          return '';
      }
    };

    const createSearchParams = () => {
      const filters = {
        ...params,
        type: state.type,
      };

      console.log('createSearchParams before');
      console.log(state.filters);
      Object.keys(state.filters).forEach((key) => {
        const value = state.filters[key];
        filters[key] = createSearchParamValue(key, value);
      });
      console.log('createSearchParams after');
      console.log(filters);
      return filters;
    };

    const loadBrands = async () => {
      const search = createSearchParams();
      const searchBrandResult = await SearchAPI.searchBrand(search);
      console.log('searchBrandResult', searchBrandResult);
      const brandRankingList = searchBrandResult.data.body.result.ranking;
      const brandRankingDenIdList = [];
      // 랭킹리스트에서 den_id만 가져온 배열 생성
      for (const brandRanking of brandRankingList) {
        brandRankingDenIdList.push(brandRanking.den_id);
      }
      // 배열을 문자열로 처리
      const denIdListString = brandRankingDenIdList.join(',');
      // 랭킹에 포함할 브랜드정보조회
      const { data } = await BrandAPI.getBrands({
        mem_id: info?.mem_id ?? 0,
        den_id: denIdListString,
      });
      // 랭킹리스트에 브랜드정보 data 추가
      for (const brandRanking of brandRankingList) {
        for (const rankingData of data.result_data) {
          if (Number(rankingData.den_id) === Number(brandRanking.den_id)) {
            brandRanking.data = rankingData;
          }
        }
      }
      // 검색과 싱크 안맞는 브랜드 제거
      const filteredBrandRankingList = brandRankingList.filter(
        (brandRanking) => brandRanking.data
      );
      // 신규입점만 필터
      const newBrandRankingList = filteredBrandRankingList.filter(
        (brandRanking) => brandRanking.new === true
      );
      state.brands = filteredBrandRankingList;
      state.newBrands = newBrandRankingList;
      state.isLoading = true;
    };

    loadBrands();

    let filterValues = null;

    (async () => {
      filterValues = await filterItems.getItemFilterOptions();
    })();

    const getFilterTags = (filters) => {
      const filterItems = [];
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        switch (key) {
          case 'concept':
          case 'age':
          case 'style':
            value.forEach((checked, idx) => {
              if (checked) {
                filterItems.push({
                  path: `${key}$$${idx}`,
                  label: filterValues[key][idx].label,
                });
              }
            });
            break;
          case 'rising':
            value &&
              filterItems.push({
                path: key,
                label: '급상승',
              });
            break;

          default:
            return '';
        }
      });
      console.log('filterItems');
      console.log(filterItems);
      return filterItems;
    };

    return {
      ...toRefs(state),
      handleUpdateItem(item) {
        state.brands = ArrayUtils.replaceByAttributeName(
          state.brands,
          item,
          'den_id'
        );
        state.newBrands = ArrayUtils.replaceByAttributeName(
          state.newBrands,
          item,
          'den_id'
        );
        loadBrands();
      },
      handleInputFilter(filters) {
        console.log('handleInputFilter');
        console.log(filters);
        state.filterSlideItems = getFilterTags(filters);
        state.isShowFilter = false;

        // ProductAPI.addCartegory(state.filterSlideItems);
        console.log(state.filterSlideItems);
        console.log('----------');
        console.log(state.selectFilter);
        loadBrands();
      },
      handleCloseNewBrand() {
        state.isShowNewBrand = false;
        loadBrands();
      },
      handleClickTag(item) {
        console.log('handleClickTag');
        console.log(item);
        const paths = item.path.split('$$');
        console.log(state.filters);
        const filters = { ...state.filters };
        console.log(paths);
        console.log(filters);

        if (paths.length > 1) {
          filters[paths[0]][paths[1]] = false;
        }

        if (paths[0] === 'price') {
          filters['price'] = undefined;
        }

        if (paths[0] === 'soldOut') {
          filters['soldOut'] = false;
        }

        state.filters = filters;

        state.filterSlideItems = getFilterTags(state.filters);

        clearSearchParams();
      },
    };
  },
};
</script>
<style>
.profile_img.brand_only {
}
.profile_img.brand_only > img {
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  margin-top: 6px;
  overflow: hidden;
  width: 30px;
}
.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  background: transparent;
  height: calc(100% - 90px);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.loading_content img {
  border-radius: 50em;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  z-index: 100;
}
@media (min-width: 1240px) {
  .loading_content {
    left: 50%;
    max-width: 360px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
