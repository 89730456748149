<template>
  <filter-layout-popup
    title="BRAND 필터"
    @close="$emit('close')"
    @reset="handleResetAll"
    @submit="$emit('input', filteredItems)"
  >
    <div class="pop_header btwlist">
      <p class="con w50">
        <input id="showPick" v-model="filteredItems.showPick" type="checkbox" />
        <label for="showPick" class="chkbox">PICK 브랜드보기</label>
      </p>
      <p class="con w50">
        <input id="showBrand" v-model="filteredItems.rising" type="checkbox" />
        <label for="showBrand" class="chkbox">급상승 브랜드보기</label>
      </p>
    </div>
    <side-tab v-if="filterItems" :options="options" value="concept">
      <side-tab-item key="concept">
        <li v-for="(item, idx) in filterItems.concept" :key="item.key">
          <ks-check-box
            v-model="filteredItems.concept[idx]"
            :item="item"
            name="concept"
            passValueKey="label"
          />
        </li>
      </side-tab-item>
      <side-tab-item key="gender">
        <li v-for="(gender, idx) in filterItems.gender" :key="gender.key">
          <KsCheckBox
            v-model="filteredItems.gender[idx]"
            :item="gender"
            name="gender"
          />
        </li>
      </side-tab-item>
      <side-tab-item key="age">
        <li v-for="(item, idx) in filterItems.age" :key="item.key">
          <ks-check-box
            v-model="filteredItems.age[idx]"
            :item="item"
            name="age"
            passValueKey="label"
          />
        </li>
      </side-tab-item>
      <side-tab-item key="style">
        <li v-for="(item, idx) in filterItems.style" :key="item.key">
          <ks-check-box
            v-model="filteredItems.style[idx]"
            :item="item"
            name="style"
            passValueKey="label"
          />
        </li>
      </side-tab-item>
    </side-tab>
  </filter-layout-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SideTab from '../../../components/ui/SideTab/SideTab.vue';
import SideTabItem from '../../../components/ui/SideTab/SideTabItem.vue';
import filterItems from '../../../consts/FilterItems';
import KsCheckBox from '../../../components/Inputs/KsCheckBox/KsCheckBox.vue';
import FilterLayoutPopup from '../../../components/popups/FilterLayoutPopup.vue';
export default {
  props: ['value'],
  components: {
    SideTab,
    SideTabItem,
    KsCheckBox,
    FilterLayoutPopup,
  },
  setup(props) {
    const state = reactive({
      filteredItems: {
        concept: [],
        style: [],
        age: [],
        gender: [],
        mySize: false,
        showPick: false,
        rising: false,
      },
      filterItems: null,
    });
    console.log('BrandFilterPopup');
    console.log(props.value);

    Object.keys(props.value).forEach(key => {
      const value = props.value[key];

      if (typeof value === 'object') {
        state.filteredItems[key] = [...value];
      } else {
        state.filteredItems[key] = value;
      }
    });

    const loadOptions = async () => {
      state.filterItems = await filterItems.getItemFilterOptions();
      console.log('loadOptions');
      console.log(state.filterItems);
    };

    loadOptions();

    return {
      ...toRefs(state),
      options: filterItems.brandTabOptions,
      handleResetAll() {
        console.log(state.filteredItems);
        state.filteredItems = {
          concept: [],
          style: [],
          gender: [],
          age: [],
          mySize: false,
          showPick: false,
          showBrand: false,
        };
      },
    };
  },
};
</script>

<style>
.price-text {
  font-size: 12px;
  margin-bottom: 8px;
}
.reset_pr {
  text-align: right;
  margin-top: 8px;
  margin-right: 14px;
}

.reset_pr button {
  text-align: right;
  min-width: 100px;
  line-height: 30px;
  padding-right: 10px;
  background: #efefef url(~@/assets/images/design/arrow-clockwise-light.svg)
    no-repeat left 10px center;
  font-size: 12px;
}
</style>
